export const PAGE_NAME = 'TondTond';
export const PAGE_DESCRIPTION = 'enjoy your events with our platform!';
export const YEAR = new Date().getFullYear();


// API
export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92 - 32,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 62,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
