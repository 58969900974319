import Cookies from 'js-cookie'
// config
// const COOKIE_SETTINGS = { secure: true, expires: 3600 };
// ----------------------------------------------------------------------
export const setCookie = ((key: string, value: string, time: number | Date | undefined) => Cookies.set(key, value, { secure: true, expires: time }))

export const getCookie = ((key: string) => Cookies.get(key))

export const removeCookie = ((key: string) => Cookies.remove(key))



