import { forwardRef } from "react";
import { Box, BoxProps } from "@mui/material";
import Image from "../image";
import { photoURL } from "../../utils/photoURL";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        component="div"
        sx={{
          width: 30,
          height: 30,
          display: "inline-flex",
          ...sx,
        }}
        {...other}
      >
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={photoURL("953e6c8f-daad-447c-bf5b-9c0244316e00")}
        />
      </Box>
    );
    return logo;
  }
);

export default Logo;
