import { Box, Fab, Fade, useScrollTrigger } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClickFab = (event: any) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Fade timeout={500} in={trigger}>
      <Box
        onClick={handleClickFab}
        role="presentation"
        sx={{ position: "fixed", bottom: 30, right: 16, zIndex: "1" }}
      >
        <Fab size="small" aria-label="scroll back to top" color="primary">
          <KeyboardArrowUpIcon
            sx={{ fontSize: 50, minHeight: 25, color: "white" }}
          />
        </Fab>
      </Box>
    </Fade>
  );
}
