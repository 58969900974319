// scroll bar
import "simplebar/src/simplebar.css";
// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
// -----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";
import { useEffect } from "react";
import packageJson from "../package.json";
import { getCookie, setCookie } from "./utils/cookie";
import moment from "moment";
// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const version = getCookie("version_blog");
    if (version !== packageJson.version || !version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          if (names)
            names.forEach((name) => {
              caches.delete(name);
            });
          window.location.reload();
        });
      }
      localStorage.clear();
      setCookie(
        "version_blog",
        packageJson.version,
        moment().add(1, "days").toDate()
      );
    }
  });

  return (
    <HelmetProvider>
      <SettingsProvider>
        <BrowserRouter>
          <ScrollToTop />
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeSettings>
                <Router />
              </ThemeSettings>
            </ThemeProvider>
          </MotionLazyContainer>
        </BrowserRouter>
      </SettingsProvider>
    </HelmetProvider>
  );
}
