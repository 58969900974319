import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const BlogPostsSearch = Loadable(
  lazy(() => import("../sections/blog/filter/BlogPostsSearch"))
);
export const SimpleFooter = Loadable(
  lazy(() => import("../components/footer/SimpleFooter"))
);
export const Footer = Loadable(lazy(() => import("../sections/Footer")));
export const BlogPostHero = Loadable(
  lazy(() => import("../sections/blog/BlogPostHero"))
);
export const BlogPostCard = Loadable(
  lazy(() => import("../sections/blog/BlogPostCard"))
);

export const BlogPostsPage = Loadable(
  lazy(() => import("../pages/blog/BlogPostsPage"))
);
export const BlogPostPage = Loadable(
  lazy(() => import("../pages/blog/BlogPostPage"))
);

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
