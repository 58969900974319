import { Navigate, useRoutes } from "react-router-dom";
// pages
import { BlogPostPage, BlogPostsPage } from "./elements";
import { PATH_BLOG } from "./paths";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: PATH_BLOG.blog,
      children: [
        { element: <BlogPostsPage />, index: true },
        { path: PATH_BLOG.post.root, element: <BlogPostPage /> },
      ],
    },
    {
      path: `${PATH_BLOG.blog}/*`,
      element: <Navigate to={`${PATH_BLOG.blog}`} />,
    },
  ]);
}
